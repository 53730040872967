<template>
    <section class="content">
        <div class="btn-box">
            <el-form>
              <el-form-item label="新密码">
                  <el-input v-model="newPwd" placeholder="密码必须由数字、字母、特殊字符组合,请输入6-16位"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="modifyPwd">修改密码</el-button>
                </el-form-item>
            </el-form>
            
            <div class="tip">
                <span>* 不能使用默认密码，每次登录都让您修改密码哦！！所以还请修改密码</span>
            </div>
        </div>
  </section>
</template>
<script>
    import * as api from '@/config/api'
    import {
        md5
    } from '@/config/md5'
    export default {
        name: 'modifyPwd',
        data() {
            return {
                newPwd: ''
            }
        },
        methods: {
            modifyPwd() {
                let reg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/
                if (!reg.test(this.newPwd)) {
                    this.$message({
                        type: 'error',
                        message: '密码必须由数字、字母、特殊字符组合,请输入6-16位'
                    })
                    return
                }
                api.modifyPwd({
                    password: md5(this.newPwd)
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.newPwd = ''
                })
            }
        }
    }
</script>
<style lang="scss">
    .tip {
        color: red
    }
</style>